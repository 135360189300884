import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Hobbie } from "./hobbies"
import useHobbies from "../hooks/use-hobbies"
import { Card } from "./card"
import useCards from "../hooks/use-cards"
import { LikeButton, ShareButton, GetInTouchButton } from "./buttons"
import { ToolBox } from "./toolbox"
import SharePopup from "./share-popup"
import { Link } from "gatsby"

const Projects = () => {
  const [isToggle, setToggle] = useState(false)
  const hobbies = useHobbies()
  const cards = useCards()
  const cardsFiltered = cards.filter(card => card.link !== "/projects")
  const hobbiesFiltered = hobbies.filter(
    hobbie => hobbie.heading1 === "Portfolio"
  )

  return (
    <div
      onClick={() => (isToggle === true ? setToggle(false) : null)}
      className="w-72 h-full flex flex-col space-y-4 lg:w-full lg:max-w-xl"
    >
      <div>
        <h1>/Projects</h1>
        <p className="subtitle mt-2">Get to know my work.</p>
      </div>
      <div className="lg:hidden">
        <Link to="/portfolio" className="shadow-md">
          {hobbiesFiltered.map(hobbie => (
            <Hobbie
              icon={hobbie.icon}
              heading1={hobbie.heading1}
              heading2={hobbie.heading2}
            />
          ))}
        </Link>
        <div className="md:hidden">{isToggle === true && <SharePopup />}</div>
        <div className="button-text flex justify-between py-4">
          <LikeButton />
          <span
            onClick={() => {
              isToggle === false ? setToggle(true) : setToggle(false)
            }}
          >
            <ShareButton />
          </span>
        </div>
      </div>

      <div className="lg:hidden flex flex-col border border-neutral-02 rounded-lg p-6 space-y-1 bg-main text-primary">
        <h3 className="">/Tool Box</h3>
        <p className="subtitle">My main resources.</p>
        <div
          onClick={() => setToggle(false)}
          className="lg:hidden  flex flex-col items-center py-4 mt-4 space-y-2"
        >
          <ToolBox />
          <GetInTouchButton />
        </div>
      </div>
      <div className="hidden lg:flex flex-row border border-neutral-02 rounded-lg p-6 space-y-1 bg-main text-primary">
        <div className="flex flex-col justify-around  p-8">
          <div>
            <h3 className="">/Tool Box</h3>
            <p className="subtitle">
              My main
              <br /> resources.
            </p>
          </div>
          <div>
            <GetInTouchButton />
          </div>
        </div>
        <div className="hidden w-1/2 lg:flex flex-row-reverse justify-center items-center p-8">
          <StaticImage
            src="../images/toolbox.png"
            placeholder="tracedSVG"
            alt="tech stack"
          />
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="w-72 hover:darktext-focus hover:scale-105">
          <Link to="/portfolio">
            {hobbiesFiltered.map(hobbie => (
              <Hobbie
                icon={hobbie.icon}
                heading1={hobbie.heading1}
                heading2={hobbie.heading2}
              />
            ))}
          </Link>
        </div>

        <div className="button-text flex justify-between py-4 lg:w-72">
          <LikeButton />
          <span
            onClick={() => {
              isToggle === false ? setToggle(true) : setToggle(false)
            }}
          >
            <ShareButton />
          </span>
        </div>
        <div className="hidden md:block">
          {isToggle === true && <SharePopup />}
        </div>
      </div>
      <div className="flex flex-col lg:hidden">
        {cards.map(
          card =>
            card.link === "/about" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
        {cards.map(
          card =>
            card.link === "/contact" && (
              <Card
                key={card.id}
                image={card.icon}
                text={card.cardText}
                link={card.link}
              />
            )
        )}
      </div>
    </div>
  )
}

export default Projects
