import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export const Hobbie = hobbie => {
  return (
    <div className={hobbie.className}>
      <div className="w-full flex items-center space-x-4">
        <div className="w-16 h-16 flex flex-col justify-center">
          <GatsbyImage image={hobbie.icon} />
        </div>
        <div className="flex flex-col py-2">
          <p className="heading-hobbie inline">{hobbie.heading1}</p>
          <p className="subheading-hobbie ">{hobbie.heading2}</p>
        </div>
      </div>
    </div>
  )
}
