import { useStaticQuery, graphql } from "gatsby"

const useHobbies = () => {
  const hobbies = useStaticQuery(graphql`
    query MyQueryHobbies {
      allContentfulHobbies {
        nodes {
          heading1
          heading2
          icon {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)
  return hobbies.allContentfulHobbies.nodes.map(hobbie => ({
    heading1: hobbie.heading1,
    heading2: hobbie.heading2,
    icon: hobbie.icon.gatsbyImageData,
  }))
}

export default useHobbies
