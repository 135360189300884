import React, { useEffect, useState } from "react"
import { EmailIcon, TwitterIcon, WhatsappIcon, TelegramIcon } from "react-share"

const SharePopup = ({ className }) => {
  return (
    <div className={className}>
      <div className="absolute ease-in delay-150 backdrop-filter backdrop-opacity-1 dark:bg-main	bg-neutral-04 w-72 flex items-center justify-around rounded shadow-md p-2">
        <a
          className="hover:transform hover:scale-110"
          href="https://api.whatsapp.com/send?text=Check this out! https://tdelgio.site/portfolio"
          action="share/whatsapp/share"
        >
          <WhatsappIcon
            size={40}
            bgStyle={{ fill: "transparent" }}
            round={true}
            title="tdelgio.site"
            iconFillColor="#9D9D9D"
          />
        </a>
        <a
          className="hover:transform hover:scale-110 "
          href="https://t.me/share/url?url=tdelgio.site/portfolio&text=Check this out!"
        >
          <TelegramIcon
            className="hover:fill-focus"
            size={40}
            bgStyle={{ fill: "transparent" }}
            round={true}
            title="tdelgio.site"
            iconFillColor="#9D9D9D"
          />
        </a>
        <a
          className="hover:transform hover:scale-110"
          href="https://twitter.com/intent/tweet?text=Check%20this%20out%20tdelgio.site/portfolio"
        >
          <TwitterIcon
            size={48}
            bgStyle={{ fill: "transparent" }}
            round={true}
            title="tdelgio.site"
            iconFillColor="#9D9D9D"
          />
        </a>
        <a
          className="hover:transform hover:scale-110"
          href="mailto:?subject=I wanted you to see this site&amp;body=Check this out! http://www.tdelgio.site/portfolio"
        >
          <EmailIcon
            size={50}
            bgStyle={{ fill: "transparent" }}
            round={true}
            title="tdelgio.site"
            iconFillColor="#9D9D9D"
          />
        </a>
      </div>
    </div>
  )
}

export default SharePopup
