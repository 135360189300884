import * as React from "react"

import Layout from "../components/layout"

import Projects from "../components/projects"

const ProjectsPage = () => (
  <Layout page="Projects">
    <Projects />
  </Layout>
)

export default ProjectsPage
